import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { footerRef } from "../../../container/footer/footer";
import { headerRef } from "../../../container/header/header";
import useFormData from "../hooks/useFormData";

/**
 * React Router once had a feature to block navigation, but it was removed in a minor version of v6.
 * It has since been re-added with an `unsafe_` prefix, but requires the router to be set up in a different way.
 * See https://reactrouter.com/en/main/routers/create-browser-router
 * In lieu of a router refactor, this hack should suffice. (Sam H. 2023-08-07)
 */
const BlockNav = () => {
	const user = useSelector((state) => state.user);
	const { currentDashboard } = useSelector((state) => state.scoreInsightsDashboard);
	const { isFormSaved } = useFormData();
	const [blockMainNav, setBlockMainNav] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const isDashboardOwner = user?.id === currentDashboard?.user_id;
	const shouldBlock = !isFormSaved && blockMainNav && isDashboardOwner;

	useEffect(() => {
		function onNavClick(e) {
			if (shouldBlock) {
				e.preventDefault();
				e.stopPropagation();
				setShowDialog(true);
			}
		}

		footerRef.current.addEventListener("click", onNavClick);
		headerRef.current.addEventListener("click", onNavClick);

		if (shouldBlock) {
			footerRef.current.classList.add("blocked");
			headerRef.current.classList.add("blocked");
		} else {
			footerRef.current.classList.remove("blocked");
			headerRef.current.classList.remove("blocked");
		}

		return () => {
			footerRef.current.removeEventListener("click", onNavClick);
			headerRef.current.removeEventListener("click", onNavClick);
		};
	}, [shouldBlock]);

	return !showDialog || !blockMainNav ? null : (
		<>
			<div className={"overlay show"}></div>
			<div className='c-modal show '>
				<div className='c-modal-inner'>
					<div className='card'>
						<div className='card-body'>
							<div className='card-title modal-header'>
								<label htmlFor=''>You have unsaved changes.</label>
							</div>
							<div className='card-content modal-body'>
								Warning! You have changed the dashboard settings. Please go back to the Executive
								Summary screen and click “SAVE” if you wish to have these changes reflected with those
								who you have shared this dashboard with.
							</div>
							<div className='modal-footer'>
								<div className='row m-4 p-4'>
									<button
										className='btn primary'
										onClick={() => {
											setBlockMainNav(false);
											setShowDialog(false);
											setTimeout(() => {
												setShowDialog(false);
												setBlockMainNav(true);
											}, 20 * 1000);
										}}
									>
										I Understand
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlockNav;
