import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useLocation, useMatch, useNavigate, useSearchParams } from "react-router-dom";

import paramRules from "../constants/paramRules";
import { roles } from "../constants/user";
import DefaultLayout from "../container/default-layout";
import { useUrlParams } from "../helper/urlParams";
import { useMixpanelTrackPageViews } from "../hooks/useMixpanel";
import { useReloadUserpilot } from "../hooks/useUserpilot";
import Collaborate from "../pages/collaborate/Collaborate.jsx";
import TwoFactorForm from "../pages/login/twoFA";
import ScoreInsightsLayout from "../pages/score-insights/layout/score-insights-layout";
import TempMapTest from "../pages/TempMapTest/TempMapTest";
import { show } from "../redux/ai/aiSlice";
import { urls } from "../redux/ai/routes.js";
import { useSelector } from "react-redux";
const { SUPER_ADMIN, MEMBER, AUTHENTICATED_GUEST } = roles;

const AddPolicy = React.lazy(() => import("../pages/policy/policy-add"));
const AddUser = React.lazy(() => import("../pages/users-dashboard/add-user-form"));
const Amazon = React.lazy(() => import("../pages/amazon/amazon"));
const AnalysisDashboard = React.lazy(() => import("../pages/analysis/analysis-dashboard"));
const CommunityProfile = React.lazy(() => import("../pages/community-profile/community-profile"));
const CompanyUserList = React.lazy(() => import("../pages/users-dashboard/comapny-view"));
const ContactUs = React.lazy(() => import("../pages/contact-us/contact-us"));
const Cowboys = React.lazy(() => import("../pages/cowboys/cowboys"));
const Dashboard = React.lazy(() => import("../pages/dashboard/dashboard"));
const DataManager = React.lazy(() => import("../pages/data-manager/data-manager-component"));
const EditPolicy = React.lazy(() => import("../pages/policy/policy-edit"));
const EditUser = React.lazy(() => import("../pages/users-dashboard/edit-user-form"));
const ErrorPage = React.lazy(() => import("../pages/error-page/error.jsx"));
const FAQ = React.lazy(() => import("../pages/faq/view-faq.jsx"));
const ForgotPassword = React.lazy(() => import("../pages/forgot-password/forgot-password"));
const IncentiveInsights = React.lazy(() => import("../pages/incentive-insights/incentive-insights"));
const LaborAnalysis = React.lazy(() => import("../pages/archive/labor-analysis/labor-analysis"));
const LaborCompetition = React.lazy(() => import("../pages/archive/labor-competition/labor-competition"));
const LaborCostArchive = React.lazy(() => import("../pages/archive/labor-cost/labor-cost"));
const LaborCost = React.lazy(() => import("../pages/score-insights/labor-cost/labor-cost"));
const LaborCostSummary = React.lazy(() => import("../pages/score-insights/labor-cost-summary/labor-cost-summary"));
const LaborCover = React.lazy(() => import("../pages/archive/labor-cover/labor-cover"));
const LaborMigration = React.lazy(() => import("../pages/score-insights/labor-migration/labor-migration"));
const LaborPotential = React.lazy(() => import("../pages/archive/labor-potential/labor-potential"));
const LaborSupplyArchive = React.lazy(() => import("../pages/archive/labor-supply/labor-supply"));
const LaborSupply = React.lazy(() => import("../pages/score-insights/labor-supply/labor-supply"));
const LaborSustainabilityArchive = React.lazy(() =>
	import("../pages/archive/labor-sustainability/labor-sustainability"),
);
const LaborSustainability = React.lazy(() =>
	import("../pages/score-insights/labor-sustainability/labor-sustainability"),
);
const Login = React.lazy(() => import("../pages/login/login"));
const ManageFAQ = React.lazy(() => import("../pages/faq/manage-faq.jsx"));
const MicroLaborAnalysis = React.lazy(() => import("../pages/micro-labor-analysis/micro-labor-analysis"));
const MicroSiteScore = React.lazy(() => import("../pages/microsite-score/microsite-score"));
const MosaicAnalysis = React.lazy(() => import("../pages/mosaic-analysis/mosaic-analysis"));
const MosaicDrill = React.lazy(() => import("../pages/mosaic-drill/mosaic-drill"));
const MsaRanking = React.lazy(() => import("../pages/msa-ranking/msa-ranking"));
const NationalInsights = React.lazy(() => import("../pages/national-insights/national-insights"));
const PDF = React.lazy(() => import("../pages/pdf/pdf"));
const Policy = React.lazy(() => import("../pages/policy/policy.jsx"));
const PotentialEmployeeAddress = React.lazy(() =>
	import("../pages/potential-employee-address/potential-employee-address"),
);
const PotentialEmployee = React.lazy(() => import("../pages/potential-employee/potential-employee"));
const PropertyView = React.lazy(() => import("../pages/property-view/property-view"));
const ResetPassword = React.lazy(() => import("../pages/forgot-password/reset-password"));
const SignUp = React.lazy(() => import("../pages/sign-up/sign-up.jsx"));
const SocCodeBlueDashboard = React.lazy(() => import("../pages/townson-dashboard/soc-code-blue-dashboard"));
const SocCodeDashboard = React.lazy(() => import("../pages/townson-dashboard/soc-code-dashboard"));
const TotalMarketScore = React.lazy(() => import("../pages/score-insights/total-market-score/total-market-score"));
const DecisionMatrix = React.lazy(() => import("../pages/score-insights/decision-matrix/decision-matrix"));

const TotalScore = React.lazy(() => import("../pages/total-score/total-score"));
const TownsonDashboard = React.lazy(() => import("../pages/townson-dashboard/townson-dashboard"));
const TownsonDashboardList = React.lazy(() => import("../pages/townson-dashboard-list/townson-dashabord-list"));
const Users = React.lazy(() => import("../pages/users-dashboard/users"));
const Administration = React.lazy(() => import("../pages/administration/Administration.jsx"));
const WageAnalysis = React.lazy(() => import("../pages/wage-analysis/wage-analysis"));

const ScoreInsightsList = React.lazy(() => import("../pages/score-insights/score-insights-list"));

const WisconsinAnalysis = React.lazy(() => import("../pages/wisconsin-analysis/wisconsin-analysis"));
const SendMagicLinkSendMagicLink = React.lazy(() =>
	import("../components/shareDashboard/shareDashboard").then((module) => ({
		default: module.SendMagicLinkSendMagicLink,
	})),
);

const MagicLinkHandshake = React.lazy(() =>
	import("../components/shareDashboard/shareDashboard").then((module) => ({
		default: module.MagicLinkHandshake,
	})),
);

const LaborShed = React.lazy(() => import("../pages/labor-shed/index"));

function Router(props) {
	useMixpanelTrackPageViews();
	useReloadUserpilot();
	const currentUser = useSelector((state) => state.user);

	useUrlParams(paramRules);

	const [searchParams] = useSearchParams();
	const idParam = searchParams.get("id");

	const dispatch = useDispatch();

	const location = useLocation();
	const navigate = useNavigate();

	const scoreMatch = useMatch("/score-insights/*");
	const busMatch = useMatch("/business-insights*");

	useEffect(() => {
		dispatch(show(false));

		const isPathnameIncluded = urls.includes(location.pathname);

		const isScoreMatched = !!scoreMatch;
		const isBusMatched = !!busMatch;

		if (isPathnameIncluded || isScoreMatched || isBusMatched) {
			dispatch(show(true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		const isAuthenticated = props && props.props.token && props.props.user;

		if (isAuthenticated) {
			const storedRedirectUrl = localStorage.getItem("collaborateRedirectUrl_nonPersist");
			if (storedRedirectUrl) {
				console.log("here we go", storedRedirectUrl);
				navigate(storedRedirectUrl);
				localStorage.removeItem("collaborateRedirectUrl_nonPersist");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.props.token, props.props.user, navigate]);

	return (
		<Routes>
			<Route exact path='/temp-map-test' element={<TempMapTest />} />
			{/* PUBLIC ROUTES */}
			<Route exact path='/guest-dashboard-access/:variable' element={<SendMagicLinkSendMagicLink />} />
			<Route exact path='/guest-dashboard-entry/:variable' element={<MagicLinkHandshake />} />
			<Route exact path='/error' element={<ErrorPage />} />
			<Route exact path='/login' element={<Login />} />
			<Route exact path='/sign-up' element={<SignUp />} />
			<Route exact path='/forgot-password' element={<ForgotPassword />} />
			<Route exact path='/reset-password' name='Reset Password' element={<ResetPassword />} />

			{/* ONLY AVAILABLE WHEN NOT LOGGED IN */}
			{props && !props.props.token && !props.props.user && (
				<>
					<Route exact path='/' name='Login' element={<Login />} />

					<Route exact path='/faq' element={<FAQ />} />
					<Route exact path='/contact-us' element={<ContactUs />} />
					<Route path='*' element={<Navigate to='/login' />} />
				</>
			)}

			{/* ONLY AVAILABLE WHEN LOGGED IN */}
			{props && props.props.token && props.props.user && (
				<>
					{props.props.user.is_2fa_enabled === 1 && props.props.user.has_2fa_verified === false ? (
						<>
							<Route path='*' element={<Navigate to='/verify-auth' />} />
							<Route exact path='/verify-auth' element={<TwoFactorForm />} />
						</>
					) : (
						<>
							<Route exact path='/policy' element={<Policy />} />
							<Route exact path='/collaborate' element={<Collaborate />} />
							<Route path='/' element={<DefaultLayout />}>
								{/* Role: 3 (Guest / Magic Link) */}
								{/* (!) These routes are mirrored in the backend API service */}
								{(props.props.user.role <= AUTHENTICATED_GUEST ||
									props.props.user.role_name !== null) && (
									<>
										<Route
											exact
											path='business-insights'
											name=''
											element={idParam ? <TownsonDashboard /> : <Navigate to='/' />}
										/>

										{/* Score Insights dashboard is sharable. The dashboard list page is not; remains under Role 2 */}
										<Route
											exact
											path='score-insights'
											name=''
											element={
												props.props.user.role === AUTHENTICATED_GUEST ||
												currentUser.role_name === "Guest" ? (
													<Navigate to='/policy' />
												) : (
													<ScoreInsightsList />
												)
											}
										/>
										<Route exact path='score-insights' element={<ScoreInsightsLayout />}>
											<Route
												exact
												path='total-market-score/:dashboardId?'
												name='Total Market Score'
												element={<TotalMarketScore />}
											/>
											<Route
												exact
												path='total-market-score/:dashboardId/decision-matrix'
												name='Decision Matrix'
												element={<DecisionMatrix />}
											/>
											<Route
												exact
												path='labor-supply/:dashboardId?'
												name='Labor Supply'
												element={<LaborSupply />}
											/>
											<Route
												exact
												path='labor-cost/:dashboardId?'
												name='Labor Cost'
												element={<LaborCost />}
											/>
											<Route
												exact
												path='labor-migration/:dashboardId?'
												name='Labor Migration'
												element={<LaborMigration />}
											/>
											<Route
												exact
												path='labor-sustainability/:dashboardId?'
												name='Labor Migration'
												element={<LaborSustainability />}
											/>
											<Route
												exact
												path='labor-cost-summary/:dashboardId?'
												name='Labor Cost Summary'
												element={<LaborCostSummary />}
											/>
										</Route>
									</>
								)}

								{/* Role: 2 (User) */}

								{(props.props.user.role <= MEMBER || currentUser.role_name !== "Guest") && (
									<>
										<Route exact path='' name='Dashboard' element={<NationalInsights />} />
										<Route
											exact
											path='archive/labor-competition'
											name=''
											element={<LaborCompetition />}
										/>
										<Route
											exact
											path='archive/labor-cost'
											name='Labor Cost'
											element={<LaborCostArchive />}
										/>
										<Route
											exact
											path='archive/labor-study-cover'
											name=''
											element={<LaborCover />}
										/>
										<Route
											exact
											path='archive/labor-supply'
											name='Labor Supply'
											element={<LaborSupplyArchive />}
										/>
										<Route
											exact
											path='archive/labor-sustainability'
											name=''
											element={<LaborSustainabilityArchive />}
										/>
										<Route
											exact
											path='archive/potential-sites'
											name=''
											element={<LaborPotential />}
										/>
										<Route
											exact
											path='archive/site-labor-analysis'
											name=''
											element={<LaborAnalysis />}
										/>
										<Route exact path='/policy' element={<Policy />} />
										<Route exact path='/pdf' element={<PDF />} />
										<Route exact path='/faq' element={<FAQ />} />
										<Route exact path='/contact-us' element={<ContactUs />} />
										<Route exact path='/dashboard' name='Dashboard' element={<Dashboard />} />
										<Route exact path='amazon' name='' element={<Amazon />} />
										<Route
											exact
											path='business-insights-list'
											name=''
											element={<TownsonDashboardList />}
										/>
										<Route
											exact
											path='business-insights-new-soc-code'
											name=''
											element={<SocCodeBlueDashboard />}
										/>
										<Route
											exact
											path='business-insights-soc-code'
											name=''
											element={<SocCodeDashboard />}
										/>
										<Route
											exact
											path='community-insights'
											name='Community Insights'
											element={<CommunityProfile />}
										/>
										<Route
											exact
											path='incentive-insights'
											name=''
											element={<IncentiveInsights />}
										/>
										<Route
											exact
											path='labor-study-employee-mosaic'
											name=''
											element={<MosaicAnalysis />}
										/>
										<Route
											exact
											path='labor-study-mosaic-drilldown'
											name=''
											element={<MosaicDrill />}
										/>
										<Route
											exact
											path='labor-study-potential-employee'
											name=''
											element={<PotentialEmployee />}
										/>
										<Route
											exact
											path='labor-study-wage-analysis'
											name=''
											element={<WageAnalysis />}
										/>
										<Route
											exact
											path='living-wage-analysis'
											name='Living Wage Analysis'
											element={<AnalysisDashboard />}
										/>
										<Route
											exact
											path='micro-labor-analysis'
											name=''
											element={<MicroLaborAnalysis />}
										/>
										<Route
											exact
											path='microsite-score-example'
											name=''
											element={<MicroSiteScore />}
										/>
										<Route
											exact
											path='msa-ranking-coverage-zone'
											name=''
											element={<MsaRanking />}
										/>
										<Route
											exact
											path='national-insights'
											name='National Insights'
											element={<NationalInsights />}
										/>
										<Route
											exact
											path='potential-employee-address'
											name=''
											element={<PotentialEmployeeAddress />}
										/>
										<Route exact path='property-view' name='' element={<PropertyView />} />
										<Route
											exact
											path='street-insights'
											name='Street Insights'
											element={<Cowboys />}
										/>
										<Route exact path='total-market-score' name='' element={<TotalScore />} />
										<Route
											exact
											path='wisconsin-analysis'
											name=''
											element={<WisconsinAnalysis />}
										/>
										<Route exact path='score-insights' element={<ScoreInsightsList />} />

										<Route exact path='/edit-user' name='Edit Users' element={<EditUser />} />

										<Route exact path='labor-shed' name='Labor Shed' element={<LaborShed />} />
									</>
								)}

								{/* Role: 1 (Admin) */}
								{props.props.user?.role_can?.view_admin === true && (
									<>
										<Route
											name='Administration'
											path='/administration/*'
											element={<Administration />}
										/>
										<Route path='/users' element={<Users />}></Route>
										<Route
											exact
											path='/company-user'
											name='Company Users'
											element={<CompanyUserList />}
										/>
										<Route exact path='/add-user' name='Add Users' element={<AddUser />} />
										<Route exact path='/edit-user' name='Edit Users' element={<EditUser />} />
										<Route
											exact
											path='/data-manager'
											name='Data Manager'
											element={<DataManager />}
										/>
									</>
								)}

								{/* Role: 0 (SuperAdmin) */}
								{props.props.user.role === SUPER_ADMIN ||
									(props.props.user?.role_can?.view_admin === true && (
										<>
											<Route exact path='/add-policy' name='Add Policy' element={<AddPolicy />} />
											<Route
												exact
												path='/edit-policy'
												name='Edit Policy'
												element={<EditPolicy />}
											/>
											<Route exact path='/manage-faq' name='Manage FAQ' element={<ManageFAQ />} />
										</>
									))}

								<Route path='*' element={<ErrorPage />} />
							</Route>
						</>
					)}
				</>
			)}

			{/* CATCH ANYTHING THAT SLIPS THROUGH */}
			<Route path='*' element={<Navigate to='/login' />} />
		</Routes>
	);
}

export default Router;
